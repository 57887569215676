import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

export const Slider = () => {

    const [ sliders, setSliders ] = useState([])
    const getSliders = async () => {

        const url = 'https://api.dworldmachine.com.ar/api/slider';

        await axios.get( url )
            .then(resp => {
                setSliders(resp.data.sliders.filter(s => s.activo === true))
            })
    }
    useEffect(() => {
        getSliders()
    }, [])


    return (
        <div>
            <Carousel className="slider-home" indicators={true}>
                {
                    sliders.map(slider => (
                        <Carousel.Item key={slider.id}>
                            <img
                                className="d-block w-100 carousel-img"
                                src={slider.imagen}
                                alt={ slider.titulo }
                            />
                            <Carousel.Caption>
                            <div className="caption">
                                <div className="carousel-content">
                                    <h3>{slider.titulo}</h3>
                                    {
                                        ( slider.bajada !== '' ) &&
                                        <p>{ slider.bajada }</p>
                                    }
                                    {
                                        ( slider.url !== '' ) &&
                                        <a target="_blank" href="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta" className="no-blue">
                                            Ver más
                                        </a>
                                    }
                                </div>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
                }
            </Carousel>
            <div className="carousel-button-actions">
                <Link to="/products">Productos</Link>
                <Link target="_blank" to="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta">Cotizá ahora</Link>
            </div>
        </div>

    )
}