import { muni } from '../municipios';

export const getCiudades = ( provId ) => {

    const cities = muni.municipios.filter( c =>  c.provincia.id === provId ).sort();
    const options = cities.map( c => (
        {
            label: c.nombre,
            value: c.nombre
        }
    ) )

    return options;
}




