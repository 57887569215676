import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedinIn, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share'
import ShareIcon from '@material-ui/icons/Share';

export const RedesShare = ( { redesObj } ) => {

    const [ shareOpen, setShareOpen ] = useState( false );
    const { url, titulo } = redesObj;

    const triggerShare = () => {
        setShareOpen( !shareOpen );
    }

    return (
    <div className="social-media-container">
        <div onClick={ triggerShare } className="share-actions-icon">
            <ShareIcon />
        </div>
            <div className="social-media-icons"
            style= {{
                display: ( shareOpen ? 'flex' : 'none' )
            }}
            >
                <div>
                    <FacebookShareButton
                        url={ url }
                        quote={ titulo }
                    >
                        <FontAwesomeIcon icon={faFacebook} />
                    </FacebookShareButton>
                </div>
                <div>
                    <WhatsappShareButton
                        url={ url }
                        title={ titulo }
                    >
                        <FontAwesomeIcon icon={ faWhatsapp } />
                    </WhatsappShareButton>
                </div>
                <div>
                    <LinkedinShareButton
                        url={ url }
                        title={ titulo }
                    >
                        <FontAwesomeIcon icon={ faLinkedinIn } />
                    </LinkedinShareButton>
                </div>
            </div>
    </div>
)
}
