import React, { useEffect, useState } from 'react';
import { PostItem } from '../components/PostItem';
import ChevronLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { setDocumentHead, novedadesDH } from '../helpers/setDocumentHead';
import { Link } from 'react-router-dom';
import axios from 'axios';

export const Novedades = () => {

    /**  **/
    setDocumentHead( novedadesDH );

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    /** **/

    const [ novedades, setNovedades ] = useState([])
    const getNovedades = async () => {

        const url = 'https://api.dworldmachine.com.ar/api/novedad';

        await axios.get( url )
            .then(resp => {
                setNovedades(resp.data.novedades.filter(n => n.activo === true))
            })
    }
    useEffect(() => {
        getNovedades()
    }, [])

    return (
        <>
            <div className="section-top">
                <Link className="no-blue" to="/">
                    <ChevronLeftIcon />
                </Link>
                <h1>Novedades</h1>
            </div>
            
            <div className="novedades-list">
                {
                    novedades &&
                    novedades.map(post => (
                        <PostItem key={post.id} post={post} />
                    ))
                }
            </div>
        </>
    )
}
