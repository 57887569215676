import React from 'react';
import ReactPlayer from 'react-player';

export const Video = ({ url }) => {

    return (
        <div className="video">
            <div className="reproductor">
                <ReactPlayer
                url={ url }
                width= '100%'
                height= '100%'
                controls = { true }
                className="react-player"
                /> 
            </div>
        </div>
    )
}
