import React from 'react'
import { faInstagram, faLinkedinIn, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BottomBar } from '../components/BottomBar';
import { useLocation } from 'react-router-dom';

export const Footer = () => {

    const location = useLocation().pathname;

    return (
        <footer>
            <div className="footer-content">
                <div className="footer-logo-container footer-block">
                    <img src="/assets/Logo2x.png" alt="Logo DWM"/>
                </div>

                <div className="footer-block">
                    <h3 className="footer-content-desc">
                    Somos importadores estratégicos de equipos y herramientas especializadas para la manufactura, reciclado, logística y construcción.
                    </h3>
                    <div className="footer-content-location">
                        <h3>
                            RP13 KM 52
                        </h3>
                        <p>
                            (2453) Carlos Pellegrini
                        </p>
                        <p>
                            Santa Fe - Argentina
                        </p>
                        <p>
                            <a rel="noreferrer" className="no-blue" href="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta" target="_blank"><FontAwesomeIcon icon={faWhatsapp} />
                            +54 9 3401 511 095</a>
                        </p>
                        <p>
                            info@dworldmachine.com.ar
                        </p>


                    </div>
                </div>
                
                <div className="footer-block">
                    <div className="footer-content-schedule">
                        <h3>
                            Horarios
                        </h3>
                        <p>
                            Lunes a viernes de 8.00 a 17.30 hs
                        </p>
                    </div>
                    <div className="footer-social-media">
                        <h3>Seguinos</h3>
                        <div className="redes-icons">
                            <a rel="noreferrer" className="no-blue" href="https://www.instagram.com/dwmoficial" target="_blank">
                                <div className="social-media-icon">
                                <FontAwesomeIcon icon={faInstagram} />
                                </div>
                            </a>
                            <a rel="noreferrer" className="no-blue" href="https://www.facebook.com/dworldmachine" target="_blank">
                                <div className="social-media-icon">
                                <FontAwesomeIcon icon={faFacebook} />
                                </div>
                            </a>
                            <a rel="noreferrer" className="no-blue" href="https://www.linkedin.com/company/dwmoficial" target="_blank">
                                <div className="social-media-icon">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>

                <div className="footer-block">
                    <div className="footer-content-newsletter">
                        <h3>Newsletter</h3>
                        <p>Recibi todas nuestras novedades nuevos ingresos en tu mail</p>
                    </div>
                    <div>
                        <a rel="noreferrer" className="newsletter-button" href="http://eepurl.com/hw28zj" target="_blank">
                            Suscribite
                        </a>
                    </div>
                </div>
                
            </div>
            {
                location.includes('/subcategory/product/') ||
                <BottomBar />
            }
            
        </footer>
    )
}
