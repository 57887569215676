export const homeDH = {
    title: 'Líderes en Importación de Maquinaria Industrial - DWM',
    description: 'Somos importadores estratégicos de equipos y herramientas especializadas para en la manufactura y reciclado.'
};

export const novedadesDH = {
    title: 'Novedades del sector industrial - DWM',
    description: 'Toda la actualización del rubro industrial, tendencias, novedades y más sobre maquinaría industrial.'
};

export const postDH = {
    title: 'Novedad - DWM',
    description: 'Toda la actualización del rubro industrial, tendencias, novedades y más sobre maquinaría industrial.'
};

export const faqDH = {
    title: 'Preguntas frecuentes sobre importación - DWM',
    description: 'Todas las respuestas sobre importación de maquinaria industrial podes encontrarla acá.'
};

export const aboutDH = {
    title: 'Importadores de Máquinas Industrial - DWM',
    description: 'Desde 2009 somos la empresa referente en el mercado de la maquinaria industrial de Argentina.'
};

export const productosDH = {
    title: 'Maquinaria industrial - DWM',
    description: 'La mejor maquinaria importada del mercado disponible en Argentina.'
};

export const categoriaDH = {
    title: "Categoría - DWM",
    description: 'La mejor maquinaria importada del mercado disponible en Argentina.'
};

export const productoDH = {
    title: "Producto - DWM",
    description: 'La mejor maquinaria importada del mercado disponible en Argentina.'
};

export const contactoDH = {
    title: 'Contacto - DWM',
    description: 'Podés contactarnos a través de nuestros medios de comunicación y resolver tus consultas.'
};


/**** *****/
export const setDocumentHead = ( {title, description} ) => {
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute("content", description);
}