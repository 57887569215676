import React from 'react';
import { useNavigate } from 'react-router-dom';

export const ProductsOptionsSubcat = ( { catId, grupoSelected, grupos, renderCategoria } ) => {

    const navigate = useNavigate();

    const navegar = ( ruta ) => {
        navigate( `/subcategory/${ruta}` );

        renderCategoria( catId );
        window.scrollTo(0, 0)
    }

    return (
        <>
        {
            grupos && grupos.map( (grupo) => (
                    
                    <div onClick={ () => navegar(`${ catId }_${ grupo.titulo }`) }
                        key={ grupo._id }
                        className={grupoSelected === grupo.titulo ? "item-product selected" : "item-product"}
                    >
                        { grupo.titulo }
                    </div>
                
            ))
        }
        </>
    )
}
