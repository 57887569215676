import { RelatedProdBox } from './RelatedProdBox';

export const FeaturedProducts = () => {
    const updateRender = () => {
    }
    
    return (
        <>
        <RelatedProdBox
        title='Productos destacados'
        updateRender={ updateRender } />
        </>
    )

}

