import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ChevronLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { AsidePostItem } from '../components/PostItem';
import { setDocumentHead } from '../helpers/setDocumentHead';
import { RedesShare } from './RedesShare';
import axios from 'axios';
import parse from "html-react-parser";
import { FsLoader } from './FsLoader';

export const PostScreen = () => {

    /**  **/
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    /** **/

    const { postId } = useParams();
    const [ novedad, setNovedad ] = useState({});
    const [ contParse, setContParse ] = useState('');
    const [ redesObj, setRedesObj ] = useState({});

    const [ loader, setLoader ] = useState( true );

    const registrarVista = useCallback(async ( cant ) => {
        const url = `https://api.dworldmachine.com.ar/api/novedad/${postId}`;
        const vistas = cant + 1;
        const payload = { vistas };
            await axios.put( url, payload )
            .then(resp => { console.log( resp ); })
            .catch(function ( error ) {
                     console.log( error );
            });
    },[postId])

    const getNovedad = useCallback(async () => {
        const url = `https://api.dworldmachine.com.ar/api/novedad/${ postId }`;

        await axios.get( url )
            .then(resp => {
                setNovedad( resp.data.novedad );
                setContParse( parse( resp.data.novedad.descripcion ) );
                setRedesObj({
                    'url': window.location.href,
                    'titulo': resp.data.novedad.titulo
                });
                registrarVista( resp.data.novedad.vistas );
                quitarLoader();

                const description = resp.data.novedad.bajada === '' ? 'Toda la actualización del rubro industrial, tendencias, novedades y más sobre maquinaría industrial.' : resp.data.novedad.bajada; 
                setDocumentHead( {
                    title: resp.data.novedad.titulo + " - DWM",
                    description
                } );
            })
    },[postId, registrarVista]);

    const [render, setRender] = useState(0);
    const updateRender = () => {
        return setRender( r => r+1 );
    }

    useEffect(() => {
        getNovedad();
        setLoader( true );
    }, [ render, getNovedad ]);

    const quitarLoader = () => {
        const timer = () => {
            setLoader( false );
        }
        setTimeout(timer, 1200);
    }

    

    const [ lastNovedades, setLastNovedades ] = useState([]);
    const [ masLeidas, setMasLeidas ] = useState([]);

    const getNovedades = async () => {

        const url = 'https://api.dworldmachine.com.ar/api/novedad';

        await axios.get( url )
            .then(resp => {
                const activas = resp.data.novedades.filter(n => n.activo === true)
                setLastNovedades( activas.reverse().slice( 0, 4 ) )
                setMasLeidas( activas.sort((a, b) => {
                    return a.vistas - b.vistas
                }).reverse().slice( 0, 4 ) )
            })
    }

    useEffect(() => {
        getNovedades();
    }, [])

    /** **/

    const { fecha, titulo, imagen } = novedad;

    const formatDate = (date) => {
        return moment(date).format('D/MM/YYYY');
    }

    return (
        <div className="post-screen-content">
            <FsLoader estado={ loader }/>
            <div className="post-nota animate__animated animate__fadeIn">
            
                <div className="section-top post">
                    <Link to="/novedades" className="no-blue">
                        <ChevronLeftIcon />
                    </Link>
                    <h1>Novedades</h1>
                </div>

                <img src={ imagen } alt={ titulo } className=" animate__animated animate__fadeIn"/>
                
                <div className="post-screen-actions">
                    <div>
                        <h2>{ titulo }</h2>
                        <div className="date">{formatDate( fecha )}</div>
                    </div>
                    <RedesShare redesObj={ redesObj } />

                </div>

                <>{ contParse }</>
            </div>

                <div className="post-aside">
                <h2 className="related-notes">Últimas noticias</h2>
                {
                    lastNovedades.map(post => (
                        <AsidePostItem key={post.id} post={post} updateRender={updateRender} />
                    ))
                }
                <div className="subscribe-form">
                    <h3>Newsletter</h3>
                    <p>Recibi todas las novedades y nuevos ingresos en tu mail</p>
                    <a rel="noreferrer" className="newsletter-button" href="http://eepurl.com/hw28zj" target="_blank">
                        Suscribite
                    </a>
                </div>
                
                <h2 className="related-notes">Las más leídas</h2>
                {
                    masLeidas.map((post) => (
                        (
                        <AsidePostItem key={post.id} post={post} updateRender={updateRender} />
                        )
                    ))
                }
                </div>
        </div>
    )
}