import React, { useState, useEffect } from 'react';

export const ImagesBlock = ({ images }) => {

    const [ actual, setActual ] = useState( images[0].url );

    useEffect(() => {
        setActual( images[0].url );
    }, [images])

    return (
        <div className="images-block">
        <div className="images-block-aside">

            {
            images && images.length > 0 &&
            images.map( (img) => (

                <div className="image" key={ img._id }
                onClick={ () => { setActual( img.url ) } }
                style={{
                    backgroundImage: (`url( ${ img.url } )` )
                }}>
                </div>
            
            ))
            }

        </div>

        {
        images && images.length > 0 &&

        <div className="image-actual"
        style={{ backgroundImage: images.length > 0
                ? (`url( ${ actual } )`)
                : null }}
                >
        </div>

        }

    </div>
    )


}
