import React, { useEffect } from 'react';
import { ContactForm } from '../components/ContactForm';
import { Video } from '../components/Video';
import { setDocumentHead, aboutDH } from '../helpers/setDocumentHead';
import { Link } from 'react-router-dom';

export const QuienesSomos = () => {

    /**  **/
    setDocumentHead(aboutDH);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    /** **/

    const handleClick = () => {
        console.log('click');
    }

    return (
        <div>
            <div className="quienes-somos-banner">
                <img className="quienes-somos-banner-img" src="https://cdn.pixabay.com/photo/2016/03/04/19/36/gears-1236578_960_720.jpg" alt="DWM quienes somos" />
                <div className="quienes-somos-banner-content">
                    <h1>Tecnología y compromiso</h1>
                    <p>
                        Desde 2008 somos referentes en el mercado de la maquinaria industrial. Importamos equipos y herramientas especializadas para la manufactura, reciclado, logística y construcción.
                    </p>
                    <Link target="_blank" to="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta">
                        <button onClick={() => handleClick()}>
                            Quiero asesorarme con un profesional
                        </button>
                    </Link>
                </div>
            </div>
            <div className="quienes-somos-content" style={{ position: 'relative', zIndex: 4 }}>
                <h2>Quiénes somos</h2>
                <p>Somos importadores estratégicos de equipos y herramientas especializadas para la manufactura, reciclado, logística y construcción.</p>
                <h2>Nuestra misión</h2>
                <p>Nuestra misión consiste en contribuir con la expansión y fortalecimiento de la industrialización del país, importando equipamiento de calidad y eficiencia, a precios accesibles, facilitando diversos instrumentos de financiación para PyMES.</p>
                <h2>Nuestros valores</h2>
                <p>Desde el 2008 nos posicionamos como la empresa referente en el mercado de la maquinaria industrial de Argentina. La esencia de nuestra tarea es conectar los proyectos con las soluciones. Hacer posibles las ideas de nuestros clientes.
                    <span className="subtit">Compromiso:</span> Nos comprometemos con los requisitos de nuestros clientes y asumimos el reto de atender todo requerimiento interno y externo de manera oportuna, innovadora y eficaz.
                    <span className="subtit">Cercanía y compromiso con la cadena de valor:</span> somos una compañía cercana y comprometida con toda la cadena de valor que va desde proveedores, colaboradores y accionistas, hasta clientes, consumidores y comunidad en general. Estamos convencidos que crecemos si todos crecen.
                    <span className="subtit">Innovación:</span> Tomamos la innovación como una forma permanente de operar, con el fin de lograr resultados mejores en cada proyecto.
                    <span className="subtit">Crecimiento:</span> Buscamos brindar oportunidades de crecimiento continuo hacia nuestro equipo, tanto en el aspecto profesional, como en su desarrollo personal.</p>

            </div>
            <Video url="https://www.youtube.com/embed/5hEext3HIO4" />
            {/* <div className="quienes-somos-contact">
                <ContactForm origen={'home'} />
            </div> */}
        </div>
    )
}
