import React from 'react';
import { faInstagram, faLinkedinIn, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageIcon from '@material-ui/icons/Language';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link, NavLink } from 'react-router-dom';
import { SearchToolbar } from './SearchToolbar';

export const NavbarDesktop = () => {

    return (
        <div className="desktop-navbar">
            <a  rel="noreferrer" href="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta"
            target="_blank"
            className="no-blue">
            <div className="whatsapp-desktop">
                <img src="/assets/whatsapp-logo.png" alt="WhatsApp Logo"/>
            </div>
            </a>
            <div className="row-navbar">
                <div className="logo-container">
                    <Link
                        className="no-blue"
                        to="/"
                    ><img src="/assets/Logo2x.png" alt="DWM Logo"/></Link>
                </div>
            
                <div className="nav-desktop-right">
                    <div className="desktop-search">
                        <SearchToolbar origen={ "desktop" }/>
                    </div>

                    <div className="nav-desktop-home">
                    <NavLink to="/">
                        <HomeIcon className="icon" /> 
                    </NavLink>
                    </div>

                    <div className="nav-desktop-lang">
                        <div>
                            <LanguageIcon className="icon" />
                        </div>
                        <span> ESPAÑOL </span>
                        <div>
                            <KeyboardArrowDownIcon className="chevron-icon" />
                        </div>
                    </div>

                    <div>
                        <div className="nav-desktop-social-media">
                            <a rel="noreferrer" className="no-blue" href="https://www.instagram.com/dwmoficial" target="_blank">
                                <div className="social-media-icon">
                                <FontAwesomeIcon icon={faInstagram} />
                                </div>
                            </a>
                            <a rel="noreferrer" className="no-blue" href="https://www.facebook.com/dworldmachine" target="_blank">
                                <div className="social-media-icon">
                                <FontAwesomeIcon icon={faFacebook} />
                                </div>
                            </a>
                            <a rel="noreferrer" className="no-blue" href="https://www.linkedin.com/company/dwmoficial" target="_blank">
                                <div className="social-media-icon">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                                </div>
                            </a>
                        </div>
                        
                    </div>

                </div>
            </div>

            <div className="row-navbar">

                <NavLink
                    className={ ({ isActive }) =>"nav-item" + (isActive ? " active" : "") } 
                    to="/products"
                >
                    Productos
                </NavLink>

                <NavLink
                    className={ ({ isActive }) =>"nav-item" + (isActive ? " active" : "") }  
                    to="/quienessomos"
                >
                    Quienes somos
                </NavLink>
                
                <NavLink
                    className={ ({ isActive }) =>"nav-item" + (isActive ? " active" : "") }  
                    to="/novedades"
                >
                    Novedades
                </NavLink>

                <NavLink
                    className={ ({ isActive }) =>"nav-item" + (isActive ? " active" : "") } 
                    to="/frequentquestions"
                >
                    Preguntas frecuentes
                </NavLink>
                
                <NavLink
                    className={ ({ isActive }) =>"nav-item" + (isActive ? " active" : "") } 
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta"
                >
                    Cotizá ahora
                </NavLink>

            </div>
        </div>
    )
}
