import React, { useState } from 'react';
import ChevDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link } from 'react-router-dom';

export const MenuMobileItem = ({ cat, handleCloseMenu }) => {

    const grupos = cat.grupos;

    const [ submenuOpen, setSubmenuOpen ] = useState( false );
    
    const handleSubmenu = () => {
        setSubmenuOpen( !submenuOpen );
    }

    return (
            <li className="menu-li-item">
                        <div className="menu-options-top submenu" onClick={ handleSubmenu }>
                            { cat.titulo }
                            { submenuOpen ? <ChevUpIcon /> : <ChevDownIcon /> }
                    </div>
                { submenuOpen &&
                    <ul>
                        {
                            ( grupos.map( g => (
                                <MenuSubcatItem
                                key={ g._id }
                                categoria={ cat.id }
                                grupo={ g.titulo }
                                handleCloseMenu={ handleCloseMenu }
                                />
                            ))
                            )       
                        }
                    </ul>
                }
            </li>
    )
}

const MenuSubcatItem = ({ grupo, categoria, handleCloseMenu }) => {

    return (
        <li>
            <Link
                to={`/subcategory/${ categoria }_${ grupo }`}
                onClick= { () => handleCloseMenu() }
            >
            { grupo }
            </Link>
        </li>
    )

}