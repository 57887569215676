import React, { useCallback, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ProductsOptionsSubcat } from '../components/ProductsOptionsSubcat';
import axios from 'axios';
import { moveArrayItemToNewIndex } from '../helpers';

const url = 'https://api.dworldmachine.com.ar/api/category';

export const ProductsOptions = ({ catSelected = '', grupoSelected = '', renderCategoria }) => {
    const [categorias, setCategorias] = useState([])

   

    const ShowSelectedFirst = useCallback(async (_categorias) => {
        const currentIndex = await _categorias.findIndex(x => x?.id === catSelected) || 0
        if(currentIndex){
            setCategorias(moveArrayItemToNewIndex(_categorias, currentIndex, 0))
        }
    },[catSelected])

    useEffect(() => {
        if (categorias) {
            ShowSelectedFirst(categorias)
        }
    }, [catSelected, ShowSelectedFirst, categorias]);
    
    const getCategorias = useCallback(async () => {
        await axios.get(url)
            .then(resp => {
                const data = resp?.data?.categorias?.filter(c => c?.activo === true)
                setCategorias(data);
                ShowSelectedFirst(data)
            })
    },[ShowSelectedFirst])

    useEffect(() => {
        getCategorias()
    }, [getCategorias])
    return (
        <div className="products-options">
            {categorias && categorias.map(cat => (
                <div className="accordion-wrapper" key={cat?.id}>
                    <Accordion defaultActiveKey={catSelected}>
                        <Accordion.Item eventKey={cat?.id}>
                            <Accordion.Header className="category">
                                {cat?.titulo} <KeyboardArrowDownIcon />
                            </Accordion.Header>
                            <Accordion.Body>
                                <ProductsOptionsSubcat
                                    catId={cat?.id}
                                    grupos={cat?.grupos}
                                    grupoSelected={grupoSelected}
                                    renderCategoria={renderCategoria}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            ))}
        </div>

    )
}