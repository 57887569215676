import React, { useState, useEffect } from 'react';
import ChevDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { NavLink } from 'react-router-dom';
import { MenuMobileItem } from './MenuMobileItem';
import axios from 'axios';

export const MenuMobile = ( { handleCloseMenu } ) => {


    const [ categorias, setCategorias ] = useState([]);
    const getCategorias = async () => {

        const url = `https://api.dworldmachine.com.ar/api/category`;

        await axios.get( url )
            .then(resp => {
                setCategorias( resp.data.categorias.filter(c => c.activo === true) );
            })
    }
    useEffect(() => {
        getCategorias()
    }, [])


    const [ submenuOpen, setSubmenuOpen ] = useState( false );

    const handleSubmenu = () => {
        setSubmenuOpen( !submenuOpen );
    }

    return (
        <aside className="navbar-menu">
            <ul className="menu-options">
                
                <li className="menu-options-item">
                <NavLink exact to="/" onClick={ () => handleCloseMenu() }> 
                    Inicio
                </NavLink>
                </li>

                <li className="menu-options-item">
                    <div className="menu-options-top" onClick={ handleSubmenu }>
                        Productos
                        { submenuOpen ? <ChevUpIcon /> : <ChevDownIcon /> }
                    </div>
                        {
                        submenuOpen &&
                            <ul>
                            {
                                ( categorias.map( cat => (
                                    <MenuMobileItem
                                        key={ cat.id }
                                        cat={ cat }
                                        handleCloseMenu = { handleCloseMenu }
                                    />
                                ))
                                )       
                            }
                            </ul>
                        }
                </li>
                <li className="menu-options-item">
                    <NavLink exact to="/quienessomos" onClick={ () => handleCloseMenu() }>
                        Quienes somos
                    </NavLink>
                </li>
                <li className="menu-options-item" onClick={ () => handleCloseMenu() }>
                    <NavLink exact to="/novedades">
                        Novedades
                    </NavLink>
                </li>
                <li className="menu-options-item" onClick={ () => handleCloseMenu() }>
                    <NavLink exact to="/frequentquestions" onClick={ () => handleCloseMenu() }>
                        Preguntas frecuentes
                    </NavLink>
                </li>
                {/* <li className="menu-options-item" onClick={ () => handleCloseMenu() }>
                    <NavLink exact to="/contacto"> 
                        Contacto
                    </NavLink>
                </li> */}
            </ul>
        </aside>
    )
}
