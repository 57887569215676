import React from 'react';
import ReactDOM from 'react-dom';
import 'animate.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'swiper/swiper-bundle.min.css';
import './styles/global.css';
import './styles/styles.css';

import AppRouter from './Routers/AppRouter';

ReactDOM.render(
    <AppRouter />,
  document.getElementById('root')
);