import React, { useState } from 'react';
import { RelatedProductItem } from '../components/RelatedProductItem'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';

export const RelatedProdBox = ({ title, related = null, updateRender }) => {

    const [productos, setProductos] = useState([])

    const getProductos = async () => {
        const url = `https://api.dworldmachine.com.ar/api/product`;

        await axios.get(url)
            .then(resp => {
                armarProductos(resp?.data?.productos?.filter(p => p?.activo === true));
            })
    };
    getProductos();

    const getProd = (productos, id) => {
        return productos.find(prod => prod?.id === id);
    }

    const armarProductos = (prod) => {
        if (related === null) {

            let featured = prod.filter(p => p?.destacado === true);
            if (featured?.length > 0) {
                setProductos(featured)
            } else {
                setProductos(prod.slice(0, 16));
            }

        } else {
            if (related.length > 0) {

                const relat = related.map(r => getProd(prod, r?.rel))
                setProductos(relat)

            } else {
                setProductos(prod?.slice(0, 16));
            }
        }
    }


    const slidesPerView = (useMediaQuery({ query: '(max-width: 1050px)' })) ? 2 : 4;

    return (
        <div className="related-products-section">
            <h2>{title}</h2>
            <Swiper
                slidesPerView={slidesPerView}
            >
                {productos &&
                    productos.map((item, index) => (
                        <SwiperSlide key={index}>
                            <RelatedProductItem item={item} updateRender={updateRender} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}
