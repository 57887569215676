import React, { useEffect } from 'react';
import { Slider } from './Slider';
import { ProductsSection } from './ProductsSection';
import { FeaturedProducts } from './FeaturedProducts';
import { ContactForm } from './ContactForm';
import { Map } from './Map';
import { setDocumentHead, homeDH } from '../helpers/setDocumentHead';

export const Home = () => {

    /**  **/
    setDocumentHead( homeDH );

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    /** **/

    return (
        <div>
            <Slider />
            <ProductsSection origen={ 'home' } />
            <FeaturedProducts />
            {/* <ContactForm origen={ 'home' } /> */}
            <Map />
        </div>
    )
}