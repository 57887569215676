import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ProductItem } from '../components/ProductItem';
import { ProductsOptions } from '../components/ProductsOptions';
import { FsLoader } from './FsLoader';
import { Link } from "react-router-dom";
import ChevronLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import { setDocumentHead } from '../helpers/setDocumentHead';

export const ProductsSubcategory = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [loader, setLoader] = useState(true);

    const { ids } = useParams();
    const [catId, grupoSelected] = ids.split('_');

    const [categoria, setCategoria] = useState([])
    const [grupoFirst, setGrupoFirst] = useState([]);

    const getCategoria = useCallback((id) => {
        const url = `https://api.dworldmachine.com.ar/api/category/${id}`;
        axios.get(url)
            .then(resp => {
                setCategoria(resp?.data?.categoria);
                setGrupoFirst(resp?.data?.categoria?.grupos[0]?.titulo);
                const description = categoria?.descripcion === '' ? 'La mejor maquinaria importada del mercado disponible en Argentina.' : categoria.descripcion;
                setDocumentHead({
                    title: resp?.data?.categoria?.titulo + " - DWM",
                    description
                });
            })
    },[categoria])
    
    useEffect(() => {
        getCategoria(catId)
    }, [catId, getCategoria])

    const grupo = grupoSelected ? grupoSelected : grupoFirst;

    const [productos, setProductos] = useState([]);
    const getProductos = () => {

        const url = `https://api.dworldmachine.com.ar/api/product`;

        axios.get(url)
            .then(resp => {
                setProductos(resp?.data?.productos?.filter(p => p?.activo === true));
                setLoader(false);
            })
    }
    useEffect(() => {
        getProductos()
    }, [])

    const products = productos.filter(prod => prod?.parentCategory === catId && prod?.parentGroup === grupo);

    const catTitle = categoria?.titulo;
    const subcatTitle = grupo;


    const renderCategoria = (id) => {
        getCategoria(id);
    }

    return (

        <div className="subcategory-page">
            <FsLoader estado={loader} />

            <div className="aside-options">
                <ProductsOptions catSelected={catId} grupoSelected={subcatTitle} renderCategoria={renderCategoria} />
            </div>

            <div className="products-list-page">

                <div className="breadcrumbs">
                    <span className="home"><Link to="/" className="no-blue"><ChevronLeftIcon /></Link></span>
                    <span><Link to="/products" className="no-blue">PRODUCTOS</Link></span>
                    <span className="active">{catTitle}</span>
                </div>

                <h2 className="subcategory">{subcatTitle}</h2>

                <div className="products-list">
                    {
                       products && products.map((product, index) => (
                            <ProductItem key={index} product={product} />
                        ))
                    }
                </div>

            </div>

        </div>


    )
}
