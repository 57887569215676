import React, { useEffect } from 'react';
import { ContactForm } from './ContactForm';
import { Map } from './Map';
import { useParams } from 'react-router';

export const ContactSection = ( { origen, prodSelected='' } ) => {

    
    const { prod } = useParams();
    useEffect(() => {

        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <ContactForm origen={ origen } prodSelected={ prod } />
        <Map />
        </>
    )
}
