import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeIcon from '@material-ui/icons/HomeOutlined';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';


export const BottomBar = () => {
    return (
        <div className="bottom-bar">

            <Link to="/" className="no-blue">
                <div className="icon-set">
                    <HomeIcon />
                    <span>HOME</span>
                </div>
            </Link>
            <Link to="/products" className="no-blue">
                <div className="icon-set">
                    <div className="icon"
                        style={{ backgroundImage: 'url("/assets/productos.svg")' }}
                    ></div>
                    <span>PRODUCTOS</span>
                </div>
            </Link>
            <a href="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta"
            target="_blank"
            rel="noreferrer"
            className="no-blue">
                <div className="icon-set">
                    <FontAwesomeIcon style={{ fontSize: '24px' }} icon={ faWhatsapp } color={ 'orange' } />
                    <span>WHATSAPP</span>
                </div>
            </a>

        </div>
    )
}
