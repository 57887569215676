import React from 'react';
import {
  HashRouter,
  Routes,
  Route
} from 'react-router-dom';

import { Navbar } from '../components/Navbar';
import { Home } from '../components/Home';
import { QuienesSomos } from '../components/QuienesSomos';
import { FrequentQuestions } from '../components/FrequentQuestions';
import { ContactSection } from '../components/ContactSection';
import { Footer } from '../components/Footer';
import { Novedades } from '../components/Novedades';
import { PostScreen } from '../components/PostScreen';
import { ProductsSection } from '../components/ProductsSection';
import { ProductsSubcategory } from '../components/ProductsSubcategory';
import { ProductScreen } from '../components/ProductScreen';

export default function AppRouter() {

  return (
    <HashRouter>
      <div>
        <Navbar />
        
        <Routes>

        <Route path="/" element={ <Home /> } />
        <Route path="/products" element={ <ProductsSection /> } />
        <Route path="/novedades" element={ <Novedades /> } />
        <Route path="/post/:postId" element={ <PostScreen /> } />
        <Route path="/quienessomos" element={ <QuienesSomos /> } />
        <Route path="/frequentquestions" element={ <FrequentQuestions /> } />
        <Route path="/contacto" element={ <ContactSection /> } />
        <Route path="/cotiza/:prod" element={ <ContactSection /> } />
        <Route path="/subcategory/:ids" element={ <ProductsSubcategory /> } />
        <Route path="/subcategory/product/:id" element={ <ProductScreen /> } />

        </Routes>

        <Footer />

      </div>
    </HashRouter>
  );
}