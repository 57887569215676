import React from 'react';

export const FichaTecnica = ({ features }) => {
    return (
        <>
            <h2 align='center'>Ficha Técnica</h2>
            {
                features.map(( feat ) => (

                <div key={ feat._id } className="data-sheet-feature">
                    <p>{ feat.carac }</p>
                    <span className="dashed-line"></span>
                    <p>{ feat.valor }</p>
                </div>

                ))
            }
        </>
    )
}
