import SearchIcon from '@material-ui/icons/Search';
import React, { useState, useEffect } from 'react';
import { SearchCombo } from './SearchCombo';
import axios from 'axios';


export const SearchToolbar = ({ closeSearch, origen }) => {

    const [productos, setProductos] = useState( [] )
    const getProductos = async () => {
        const url = `https://api.dworldmachine.com.ar/api/product`;

        await axios.get( url )
            .then(resp => {
                setProductos( resp.data.productos.filter(p => p.activo === true) );
            })
    };

    const [novedades, setNovedades] = useState( [] )
    const getNovedades = async () => {
        const url = `https://api.dworldmachine.com.ar/api/novedad`;

        await axios.get( url )
            .then(resp => {
                setNovedades( resp.data.novedades.filter(n => n.activo === true) );
            })
    };

    const [ productsResult, setProductsResult ] = useState([]);
    const [ novedadesResult, setNovedadesResult ] = useState([]);


    function searchProducts(search) {
        let productsFilteredBySearch = productos.filter(prod => prod.titulo.toLowerCase().includes(search.toLowerCase()))
        setProductsResult( productsFilteredBySearch.slice(0, 4) )
    }

    function searchNovedades(search) {
        let novedadesFilteredBySearch = novedades.filter(nov => nov.titulo.toLowerCase().includes(search.toLowerCase()))
        setNovedadesResult( novedadesFilteredBySearch.slice(0, 4) )
    }

    useEffect(() => {
        getProductos();
        getNovedades();
    }, [])

    let desk = false;
    if ( origen === "desktop" ) {
        desk = true;
    }

const [ showResult, setShowResult ] = useState( false );

const handleSearch = ({ target }) => {
    setSearch( target.value );
    triggerSearch();  
}

const handleSubmit = ( e ) => {
    e.preventDefault();
    triggerSearch();
}

const triggerSearch = () => {
    searchProducts( search );
    searchNovedades( search );
    setShowResult( true );
}

const [ search, setSearch ] = useState( '' );

const closeCombo = () => {
    setShowResult( false );
    if ( !desk ) {
       closeSearch();
    }
}

    return (

        <> { desk ?

            <>
             <form onSubmit={ handleSubmit }>
                <input 
                    className="nav-desktop-search-input"
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
                <SearchIcon className="icon" onClick={ handleSubmit } />
                </form>
                {
            
            showResult &&
                <SearchCombo productos={ productsResult } novedades={ novedadesResult } closeCombo={ closeCombo } />
            }

            </>

            :

            <div className={"navbar-search-form"}>
            <form onSubmit={ handleSubmit }>
                <input
                    placeholder="Buscar"
                    type="text"
                    name="search"
                    value={ search }
                    onChange={ handleSearch }
                    autoComplete="off"
                />
                <SearchIcon onClick={ handleSubmit } />
            </form>

        {
            showResult &&
            <SearchCombo productos={ productsResult } novedades={ novedadesResult } closeCombo={ closeCombo } />
        }

        </div>


       } </>


        

    )
}
