import React, { useState } from "react";
import { MenuMobile } from '../components/MenuMobile';
import { SearchToolbar } from "./SearchToolbar";
import { LanguageToolbar } from "./LanguageToolbar";
import { NavbarDesktop } from './NavbarDesktop'
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import LanguageIcon from '@material-ui/icons/Language';
import SearchIcon from '@material-ui/icons/Search';

export const Navbar = () => {

    // despues traer traer los default del local storage

    const handleSidebar = () => {
        setIsOpenSidebar( !isOpenSidebar );
        setIsOpenLanguageToolbar( false );
        setIsOpenSearchToolbar( false );
    }
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    
    const handleLanguageToolbar = () => {
        setIsOpenLanguageToolbar( !isOpenLanguageToolbar );
        setIsOpenSidebar( false );
        setIsOpenSearchToolbar( false );
    }
    const [isOpenLanguageToolbar, setIsOpenLanguageToolbar] = useState(false);
    
    const handleSearchToolbar = () => {
        setIsOpenSearchToolbar( !isOpenSearchToolbar );
        setIsOpenSidebar( false );
        setIsOpenLanguageToolbar( false );
    }
    const [isOpenSearchToolbar, setIsOpenSearchToolbar] = useState(false);

    const closeSearch = () => {
        setIsOpenSearchToolbar( false );
    }
    
    const handleCloseMenu = () => {
        setTimeout(() => {
            setIsOpenSidebar( false );
        }, 150);
    }

    return (
        <>
            <div className="Navbar">
                <div className="Navbar_logo-container">
                <Link
                    className="no-blue"
                    to="/"
                >
                    <img className="Navbar_logo" alt="logo" src="/assets/Logo.png" />
                </Link>
                </div>
                <div className="Navbar-actions">
                    <div className={isOpenSearchToolbar ? "Navbar-actions-icon selected" : "Navbar-actions-icon"} onClick={ handleSearchToolbar }>
                        <SearchIcon />
                    </div>
                    <div className={isOpenLanguageToolbar ? "Navbar-actions-icon selected" : "Navbar-actions-icon"} onClick={ handleLanguageToolbar }>
                        <LanguageIcon />
                    </div>
                    <div className={isOpenSidebar ? "Navbar-actions-icon selected" : "Navbar-actions-icon"} onClick={ handleSidebar }>
                        {
                            isOpenSidebar
                                ? <CancelIcon />
                                : <MenuIcon />
                        }
                    </div>
                </div>
            </div>
            
            { isOpenLanguageToolbar && <LanguageToolbar /> }
            { isOpenSearchToolbar && <SearchToolbar closeSearch={ closeSearch } /> }
            { isOpenSidebar && <MenuMobile handleCloseMenu={ handleCloseMenu } /> }
            
            <NavbarDesktop />
        </>
    )

}