import React from 'react';

export const LanguageToolbar = () => {

    return (
        <ul className="language-toolbar">
            <li className="language-toolbar-title">
                Idioma
            </li>
            <li>
                <img src="/assets/flags/es-ar.png" alt="es-ar"/>
                Español
            </li>
            <li>
                <img src="/assets/flags/en-us.png" alt="en-es"/>
                English
            </li>
        </ul>
    )
}

