import React from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

export const SearchCombo = ({ productos, novedades, closeCombo }) => {

    const navigate = useNavigate();

    const navegar = ( ruta ) => {
        navigate( ruta );
    }

    let noResult = false;
    if ( productos.length === 0 && novedades.length === 0 ) {
        noResult = true;
    }

    const handleNovedad = () => {
        closeCombo();
    }

    const handleProducto = () => {
        closeCombo();
    }

    return (

        <ul className="search-results">
            <CloseIcon className="close" onClick={ () => closeCombo() } />

            { ( productos.length !== 0 ) &&
            
                <>
                <li className="title">
                    Productos
                </li>

                {
                    productos.map( p => (
                        
                        <div key={ p.id } onClick={ () => navegar( `/subcategory/product/${ p.id }` ) }>
                            <li onClick={ handleProducto }>
                                { p.titulo }
                            </li>
                        </div>
                    ))
                }
                </>
            }  

            { ( novedades.length !== 0 ) &&
                <>
                <li className="title">
                    Novedades
                </li>
                {
                    novedades.map( n => (
                        <div key={ n.id } onClick={ () => navegar( `/post/${ n.id }` ) }>
                            <li onClick={ handleNovedad }>
                                { n.titulo }
                                
                            </li>
                        </div>
                        
                    ))
                }
                </>
            }

                { noResult && <div className="no-result">No se encontraron resultados</div> }

        </ul>
    )
}