import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const PostItem = ({ post }) => {

    const { id, titulo, bajada, fecha, imagen } = post;
    const truncatePostContent = ( content ) => {
        return content.substring(0, 120) + "..."
    }

    const formatDate = ( date ) => {
        return moment(date).format('D/MM/YYYY')
    }

    const navigate = useNavigate();

    const navegar = () => {
        navigate( `/post/${id}` );
    }

    return (
        <div className="novedades-list-item" onClick={ navegar }>
            <div
                className="img"
                style={{
                    backgroundSize: 'cover',
                    backgroundImage: `url(${( imagen ) })`,
                    backgroundPosition: 'center',
                }}></div>
            <h2>{ titulo }</h2>
            <div className="date">{ formatDate( fecha ) }</div>
            <p>{ truncatePostContent( bajada ) }</p>
        </div>
    )
}

const AsidePostItem = ({ post, updateRender }) => {

    const { id, titulo, fecha, imagen } = post;

    const formatDate = (date) => {
        return moment(date).format('D/MM/YYYY')
    }

    const navigate = useNavigate();

    const navegar = () => {
        navigate( `/post/${id}` );
        updateRender();
        window.scrollTo(0,0);
    }

    return (
        <div className="novedades-list-item" onClick={ navegar }>
            <img src={ imagen } alt="" />
            <div className="text-content">
                <div className="date">{formatDate( fecha )}</div>
                <h2>{ titulo }</h2>
            </div>
        </div>
    )

}

export {
    PostItem,
    AsidePostItem
}