import React from 'react';
import { useNavigate } from "react-router-dom";

export const ProductItem = ({ product }) => {
    
    let navigate = useNavigate();

    const handleClick = () => {
       navigate(`/subcategory/product/${ product.id }`);
    }
    
    return (
        <div className="products-list-item">
            {
                product.images && product.images.length > 0 &&

                <div className="image"
                style={{
                    backgroundImage: (`url( ${ product.images[0].url } )` )
                }}
                ></div>
            }
            
            <div className="text-wrapper">
                <h3>{ product.titulo }</h3>

                <div className="item-price">
                    <div>
                        { product.precioDolar && <p>U$ { product.precioDolar }</p> }
                        { product.precioPesos && <small>$ { product.precioPesos }</small>  }
                    </div>
                    {
                        product.nuevo ?
                            <span>NUEVO</span>
                        :
                            <span>USADO</span>
                    }
                    
                </div>
                    <button onClick={ () => handleClick() }>Consultar</button>
            </div>
        </div>
        
    )
}
