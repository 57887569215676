import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Accordion from 'react-bootstrap/Accordion';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { setDocumentHead, faqDH } from '../helpers/setDocumentHead';
import { Link } from 'react-router-dom';
import axios from 'axios';
import parse from "html-react-parser";


export const FrequentQuestions = () => {

    /**  **/
    setDocumentHead( faqDH );

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    /** **/


    const [ faqs, setFaqs ] = useState([])
    const getFaqs = async () => {

        const url = 'https://api.dworldmachine.com.ar/api/faq';

        await axios.get( url )
            .then(resp => {
                setFaqs(resp.data.faqs.filter(q => q.activo === true))
            })

    }
    useEffect(() => {
        getFaqs()
    }, [])



    return (
        <>
        <div className="faq">
            <div className="section-top">
                <Link className="no-blue" to="/">
                    <ChevronLeftIcon />
                </Link>
                <h1>Preguntas frecuentes</h1>
            </div>
            <div className="faq-container">
                <ul className="frequent-questions-list">

                    <div>
                        <Accordion>            
                            {
                                faqs.map(( faq ) => (
                                    <Accordion.Item
                                        key={ faq.id }
                                        eventKey={ faq.id }>
                                        
                                        <Accordion.Header className="faq">
                                        { faq.pregunta } <KeyboardArrowDownIcon />
                                        </Accordion.Header>
                                        <Accordion.Body className="faq-answer">
                                        { parse( faq.respuesta ) }
                                        </Accordion.Body>
                                    </Accordion.Item>

                                ))
                            }
                        </Accordion>

                    </div>
                </ul>

            </div>
        </div>


        </>
    )
}
