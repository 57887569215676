import React from 'react';
import { useNavigate } from 'react-router-dom';

export const RelatedProductItem = ({ item, updateRender }) => {

    const navigate = useNavigate();

    const navegar = () => {
        navigate(`/subcategory/product/${item?.id}`, { replace: true });
        updateRender();
        window.scrollTo(0, 0);
    }

    return (
        item && item?.images ?
        <div className="related-product-item" onClick={navegar}>
            <div className="related-product-img-container">
                {
                    item?.images?.length > 0 &&

                    <div className="related-product-item-img"
                        style={{ backgroundImage: (`url( ${item?.images[0]?.url} )`)}}
                    ></div>
                }

            </div>
            <div className="related-product-item-content">
                <p>{item?.titulo}</p>
                {item?.nuevo ?  <span>Nuevo</span> : <span>Usado</span>}
            </div>
        </div>
    : <></>
    )
}

