import React, { useEffect, useState } from 'react';
import { setDocumentHead, productosDH } from '../helpers/setDocumentHead';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FsLoader } from './FsLoader';

export const ProductsDesktop = () => {

    setDocumentHead( productosDH );

    const [ loader, setLoader ] = useState( true );
    const [ categorias, setCategorias ] = useState([])
    const getCategorias = async () => {

        const url = 'https://api.dworldmachine.com.ar/api/category';

        await axios.get( url )
            .then(resp => {
                console.log(resp)
                setCategorias(resp?.data?.categorias?.filter(c => c?.activo === true));
                setLoader( false );
            })
    }
    useEffect(() => {
        getCategorias()
    }, [])


    let navigate = useNavigate();

    function handleClick( catId ) {
       navigate(`/subcategory/${ catId }`);
    }

    return (
        <div className="products-desktop">
            <FsLoader estado={ loader }/>
            {
                categorias.map( cat => (
                    <div className="category-item" key={ cat?.id }>
                        <div className="text-wrapper">
                            <h3>{ cat?.titulo }</h3>
                            <p>{ cat?.descripcion }</p>
                            <button onClick={ () => handleClick( cat?.id ) }>Ver más</button>
                        </div>
                        <div
                        className="category-img"
                        style={{
                            backgroundImage: (`url( ${ cat?.imagen } )` )
                        }}>
                        </div>
                    </div>
                ))

            }

            </div>
    )
}
