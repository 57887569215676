import React from 'react';
import SyncIcon from '@material-ui/icons/Sync';

export const FsLoader = ( { estado } ) => {

    return (
        <div className={
                    estado ? "fs-loader visible"
                    : "fs-loader"
                }
        ><SyncIcon className="rotate" />
        </div>
    )
}



