import React, { useEffect, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { setDocumentHead, contactoDH } from '../helpers/setDocumentHead';
import axios from 'axios';
import Select from 'react-select';
import { prov } from '../data/provincias';
import { getCiudades } from '../data/selectors/getCiudades';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import Swal from 'sweetalert2';
import SyncIcon from '@material-ui/icons/Sync';

const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    })
  }

export const ContactForm = ( { origen, prodSelected='' } ) => {

    const clase = origen ? origen : '';    

    /**  **/
    origen || setDocumentHead( contactoDH );
    /** **/


    const [productos, setProductos] = useState( [] )

    const getProductos = async () => {
        const url = `https://api.dworldmachine.com.ar/api/product`;

        await axios.get( url )
            .then(resp => {
                setProductos( resp.data.productos.filter(p => p.activo === true) );
            })
    };

    useEffect(() => {
        getProductos();
    }, [])

    const optionsProd = productos.map(p => ({
        "value" : p.id,
        "label" : p.titulo
      }))

    let prodDefault;

    if ( prodSelected ) {
        prodDefault = { label: prodSelected, value: prodSelected }
    }else{
        prodDefault = null
    }

    const [ prodValue, setProdValue ] = useState( prodDefault )
    const [ provValue, setProvValue ] = useState( null );
    const [ cityValue, setCityValue ] = useState( null );

    const optionsProv = prov.provincias.map( p => ({
        "value" : p.id,
        "label" : p.iso_nombre
      }))

    const [ urgent, setUrgent ] = useState( false );

    const handleUrgent = () => {
        setUrgent( !urgent );
        setFormValues({ ...formValues, urgente: !urgent })
    }

    const [ showCiudades, setShowCiudades ] = useState( false );

    const handleProvChange = ( e ) => {
        setProvValue( e );
        setShowCiudades( true );
        setFormValues({ ...formValues, provincia: e.label })
    }

    const handleProdChange = ( e ) => {
        setProdValue( e );
        setFormValues({ ...formValues, producto: e.label })
    }

    const handleCityChange = ( e ) => {
        setCityValue( e );
        setFormValues({ ...formValues, ciudad: e.label })
    }

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [ target.name ]: target.value
        })
    }

    /* */

    const initialState = {
        producto: prodSelected,
        nombre: '',
        email: '',
        celular: '',
        provincia: '',
        ciudad: '',
        mensaje: '',
        urgente: false
    }

    const [ formValues, setFormValues ] = useState( initialState );
    const { nombre, email, celular, mensaje } = formValues;

    const handleSubmit = ( e ) => {
        e.preventDefault();

        if ( nombre.trim() === '' ) {
            Swal.fire({
                icon: 'error',
                title: 'El nombre es obligatorio'
            })
            } else if ( email.trim() === '' ) {
                Swal.fire({
                    icon: 'error',
                    title: 'El email es obligatorio'
                })
            } else if ( celular.trim() === '' ) {
                Swal.fire({
                    icon: 'error',
                    title: 'El teléfono es obligatorio'
                })
            } else {
            sendMail( formValues );
        }
    }

    const resetForm = () => {
        setFormValues( initialState );
        setProdValue( prodDefault );
        setShowCiudades( false );
        setProvValue( null );
        setCityValue( null );
        setUrgent( false );
    }
    
    const [ loader, setLoader ] = useState( false );

    const sendMail = async ( formValues ) => {
        const url = `https://api.dworldmachine.com.ar/api/mailer`;

        setLoader( true );
        await axios.post( url, formValues )
            .then(resp => {
                if ( resp.data.ok ) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Formulario enviado'
                    })
                    resetForm();
                    setLoader( false );
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error en el envío'
                    })
                    setLoader( false );
                }
            })
    };

    return (
        <div className= {`contact-form ${clase}`}>

            <div className="section-top form">
                <ChevronLeftIcon />
                <h1>Cotizá ahora</h1>
            </div>
            
            <form onSubmit={ handleSubmit }>
                <div className={
                    loader ? "loader visible"
                    : "loader"
                }
                ><SyncIcon className="rotate" /></div>
                <div className="column">

                { productos &&
                <div className="select-input">
                <Select 
                    placeholder="Seleccione un producto"
                    value={ prodValue }
                    options={ optionsProd }
                    onChange={ handleProdChange }
                    styles={ customStyles }
                />
                </div>
                }
                <input type="text" placeholder="Nombre completo o razón social"
                    name="nombre"
                    onChange={ handleInputChange }
                    autoComplete="off"
                    value={ nombre }
                
                />
                <input type="email" placeholder="E-mail"
                    name="email"
                    onChange={ handleInputChange }
                    autoComplete="off"
                    value={ email }
                />
                <input type="tel" placeholder="Celular"
                    name="celular"
                    onChange={ handleInputChange }
                    autoComplete="off"
                    value={ celular }
                />
                    
                <div className="contact-form-location">
                        
                    { prov.provincias &&
                        <div className="select-input">
                        <Select
                        onChange={ handleProvChange }
                        placeholder="Provincia"
                        options={ optionsProv }
                        styles={ customStyles }
                        value={ provValue }
                        /> 
                        </div>
                    }
                    { showCiudades && 
                        <div className="select-input">
                        <CiudadSelector provId={ provValue.value } handleCityChange={ handleCityChange } cityValue={ cityValue }  />
                        </div>
                    }

                </div>
                </div>

                <div className="column">
                    <textarea placeholder="Hola, quiero cotizar este producto. Gracias"
                        name="mensaje"
                        onChange={ handleInputChange }
                        autoComplete="off"
                        value={ mensaje }
                    ></textarea>
                    
                    <div className="contact-form-button-cont">
                        <div className="contact-form-bottom">
                            <div className="check" onClick={ handleUrgent }>
                             {
                                    urgent ?
                                    <CheckBoxOutlinedIcon />
                                   :
                                    <CheckBoxOutlineBlankOutlinedIcon />
                                    
                               }
                            <span>
                                Solicitar envío con urgencia
                            </span>   
                            </div>
                            
                        </div>
                        <button type="submit">Enviar</button>
                    </div>
                    
                </div>

            </form>
        </div>
    )
}


const CiudadSelector = ({ provId, handleCityChange, cityValue }) => {

    const options = getCiudades( provId );

    return (
        <Select
            onChange={ handleCityChange }
            placeholder="Ciudad"
            options={ options }
            styles={ customStyles }
            value={ cityValue }
        />
    )
}