import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { ProductsOptions } from '../components/ProductsOptions';
import { ProductsDesktop } from '../components/ProductsDesktop';
import { setDocumentHead, productosDH } from '../helpers/setDocumentHead';
import { Link } from 'react-router-dom';

export const ProductsSection = ( { origen } ) => {
    
    const clase = origen ? origen : '';    

    /**  **/
    origen || setDocumentHead( productosDH );

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])
    
    /** **/

    return (
        <div className={`products-section ${clase}`}>            

            <div className="section-top products">
                <Link className="no-blue" to="/">
                    <ChevronLeftIcon />
                </Link>
                <h1>Productos</h1>
            </div>
            <p>La mejor maquinaria del mercado</p>

            <ProductsOptions />
            <ProductsDesktop />

        </div>
    )

}
