import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ContactForm } from '../components/ContactForm';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Link } from 'react-router-dom';
import { Video } from './Video';
import { setDocumentHead } from '../helpers/setDocumentHead';
import { RedesShare } from './RedesShare';
import ChevronLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import axios from 'axios';
import parse from "html-react-parser";
import { ImagesBlock } from './ImagesBlock';
import { RelatedProdBox } from './RelatedProdBox';
import { FichaTecnica } from './FichaTecnica';
import { FsLoader } from './FsLoader';

export const ProductScreen = () => {
        
    /**  **/
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    /** **/

    const { id } = useParams();

    const [ producto, setProducto ] = useState({});
    const [ descParse, setDescParse ] = useState('');
    const [ redesObj, setRedesObj ] = useState({});

    const getProducto = useCallback(async () => {
        const url = `https://api.dworldmachine.com.ar/api/product/${ id }`;
        await axios.get( url )
            .then(resp => {
                setProducto( resp.data.producto );
                setDescParse( parse( resp.data.producto.descripcionLong ) );
                getCategoria( resp.data.producto.parentCategory );
                setRedesObj({
                    'url': window.location.href,
                    'titulo': resp.data.producto.titulo
                });
                
                const description = resp.data.producto.descripcionShort === '' ? 'La mejor maquinaria importada del mercado disponible en Argentina.' : resp.data.producto.descripcionShort; 
                setDocumentHead( {
                    title: resp.data.producto.titulo + " - DWM",
                    description
                } );
            })
    },[id])

    /** **/

    const [ loader, setLoader ] = useState( true );

    const [render, setRender] = useState(0);
    
    const updateRender = () => {
        return setRender( r => r+1 );
    }

    useEffect(() => {
        getProducto();
        setLoader( true );
    }, [ render, getProducto ]);

    const { parentCategory, parentGroup, titulo, images, precioDolar, precioPesos, features,
        descripcionShort, pdfFile, video, related } = producto;

    const [ categoria, setCategoria ] = useState('');
    const getCategoria = async ( id ) => {
        const url = `https://api.dworldmachine.com.ar/api/category/${ id }`;

        await axios.get( url )
            .then(resp => {
                setCategoria( resp.data.categoria.titulo );
                setLoader( false );
            })
    };


    return (
    <div className="product-screen">
        <FsLoader estado={ loader }/>

        <div className="breadcrumbs">
            <span className="home"><Link to="/" className="no-blue"><ChevronLeftIcon /></Link></span>
            <span><Link to="/products" className="no-blue">PRODUCTOS</Link></span>
            <span><Link to={ `/subcategory/${ parentCategory }_${ parentGroup }` } className="no-blue">{ categoria }</Link></span>
            <span className="active">{ parentGroup }</span>
        </div>
                        
        <h2 className="title-product">{ titulo }</h2>
        
        <div className="upper-row animate__animated animate__fadeInLeft">
           {
               images &&
               <ImagesBlock images={ images } className=" animate__animated animate__fadeIn"/>
           }
            <div className="price-short-desc-product">

                <div className="price-actions-product">
                    <div className="price-product">
                        { precioDolar && <p>U$ { precioDolar }</p> }
                        { precioPesos && <small>$ { precioPesos }</small> }
                    </div>

                    <div className="product-actions">
                        <span>NUEVO</span>
                        <RedesShare redesObj={ redesObj } />
                    </div> 
                </div>

                <div className="short-desc-product">
                    <h2>{ titulo }</h2>
                    <p>{ descripcionShort }</p>
                </div>
                <Link
                    className="no-blue" 
                    target="_blank"
                    to="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta"
                >
                    <button
                        className="cotiza-desktop"
                    >Cotizá ahora</button>
                </Link>
            </div>
        </div>

        <div className="row">
            <div className="specifications">
                { descParse &&
                    <>
                        <h2>Especificaciones</h2>
                        <>{ descParse }</>
                    </>
                }
                
            </div>
            <div className="data-sheet">
                {
                    features &&
                    <FichaTecnica features={ features } />
                }
                {
                    pdfFile !== "" &&
                    <a rel="noreferrer" href={ pdfFile } target="_blank"><button className="descargar no-blue"><DownloadIcon />
                        Descargar Ficha Técnica
                    </button></a>
                }
            </div>
            {
                video !== "" &&
                <Video url={ video } />
            }
            
        </div>
       
        {
        related &&
        <RelatedProdBox title='Productos Relacionados' related={ related } updateRender={ updateRender }/>
        }

        <div className="product-button-container">
            <Link
                className="product-button" 
                target="_blank"
                to="https://api.whatsapp.com/send?phone=5493401511095&text=%C2%A1Hola!%20Estaba%20navegando%20en%20el%20sitio%20de%20DWM%20y%20quiero%20hacer%20una%20consulta"
            >
                Cotizá ahora
            </Link>
        </div>

    </div>
        
    )
}
